<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{this.$route.query.shopName}}  ：还款</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeSubNum(false)">＜</div>
          <div>{{yearMonthArr[subNum]}}</div>
          <div class="box-right" @click="changeSubNum(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:16px">
        <span style="font-size:14px">还款汇总：{{vipMoneyAll}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="dutyStartTime" label="班次开始时间" width="140"/>
          <el-table-column prop="dutyEndTime" label="班次结束时间"  width="140"/>
          <el-table-column prop="huankuan" label="现金还款" />
          <el-table-column prop="bankHuankuan" label="网银还款" />
          <el-table-column prop="alipayHuankuan" label="支付宝还款" />
          <el-table-column prop="wechartHuankuan" label="微信还款" />
          <el-table-column prop="groupHuankuan" label="团购还款" />
          <el-table-column prop="cardHuankuan" label="储值卡还款" />
          <el-table-column prop="freeHuankuan" label="还款免单" />
          <el-table-column prop="kdbScanHuankuan" label="扫码还款" />
          <el-table-column prop="huankuanIncome" label="合计" />
        </el-table>
      </div>
    </main>
    <Print rul='report/monthReportStatistics.json' :add='"&yearMonth="+yearMonthArr[subNum]+"&chainShopId="+this.$route.query.shopId+"&name="+"huankuan"' />  </div>
</template>

<script>
import {ref} from 'vue'
import {postData} from"@a"
import {useRoute} from 'vue-router'
export default {
  setup(){
    let vipMoneyAll = ref(0)
    const route = useRoute()
    let options1 = ref([
      {value:'全部',label:'全部'},
      {value:'茶类系列',label:'茶类系列'},
      {value:'副品系列',label:'副品系列'},
      {value:'烟酒',label:'烟酒'}
    ])
    let options2 = ref([
      {value:'全部',label:'全部'},
      {value:'有',label:'有'},
      {value:'无',label:'无'}
    ])
    let options3 = ref([
      {value:'全部',label:'全部'}
    ])
    let input1 = ref('')
    let value1 = ref('全部')
    let value2 = ref('全部')
    let value3 = ref('全部')
    const dataOne = ref([
    ])

    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      return style
    }
        let newDate = new Date().getMonth()+1
    let newYear = new Date().getFullYear()
    let yearMonthArr = ref([])
    for(let i = 0;i<12;i++){
      if(newDate-i === 0){
        newDate = 13
        newYear -=1
      }
      let data = ''
      if(newDate-i>0){
        data = newYear + '-' + (newDate-i>9?newDate-i:'0'+(newDate-i))
      }else{
        let j = i - newDate
        data = newYear + '-' + ((newDate-j)>9?(newDate-j):'0'+(newDate-j))
      }
      yearMonthArr.value.unshift(data)
    }
    let subNum = ref(yearMonthArr.value.length-1)
    if(route.query.time){
      for(let i = 0;i<yearMonthArr.value.length;i++){
        if(route.query.time === yearMonthArr.value[i]){
          subNum.value = i
          break
        }
      }
    }
    const changeSubNum = (val)=>{
      console.log(val,yearMonthArr.value,subNum.value)
      if(val && subNum.value<yearMonthArr.value.length-1){
        subNum.value+=1
        infoData()
      }else if(!val && subNum.value>1){
        subNum.value -=1
        infoData()
      }
    }
    const infoData = async()=>{
      dataOne.value = []
      try{
        let param = {
          yearMonth:yearMonthArr.value[subNum.value],
          chainShopId:route.query.shopId
        }
        let res = await postData('shop/monthReportStatistics.json',param)
        vipMoneyAll.value = 0
        for(let i = 0;i<res.data.length;i++){
          res.data[i].total =  Number(res.data[i].cash) + Number(res.data[i].incomeVirtual) +Number(res.data[i].bankMoney) +Number(res.data[i].alipayMoney) +Number(res.data[i].wechartMoney) +Number(res.data[i].groupMoney) + Number(res.data[i].shezhang) + Number(res.data[i].kdbScanPay)
          vipMoneyAll.value += Number(res.data[i].huankuanIncome)
        }
          // <el-table-column prop="shezhang" label="今日挂账" />
        for(let i = 0;i<res.data.length;i++){
          res.data[i].statisticTime = res.data[i].statisticTime.substring(0,10)
          res.data[i].cash = res.data[i].incomeCashMember + res.data[i].incomeCashOther
          res.data[i].total = res.data[i].cash + res.data[i].incomeVirtual + res.data[i].bankMoney + res.data[i].alipayMoney + res.data[i].wechartMoney + res.data[i].groupMoney+ res.data[i].kdbScanPay+ res.data[i].groupMoney
        }
        dataOne.value = res.data
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      yearMonthArr,
      subNum,
      changeSubNum,
      dataOne,
      options1,
      options2,
      options3,
      input1,
      value1,
      value2,
      value3,
      headerStyle,cellStyle,
      vipMoneyAll,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16x;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>